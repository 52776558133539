<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-card
      :title="$t('Purchase')"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >

          <!-- Header -->
          <b-row>

            <!-- Field: Date -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="purchase-date"
                rules="required"
              >
                <b-form-group
                  :label="$t('Date')"
                  label-for="purchase-date"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      id="purchase-date"
                      v-model="purchaseData.purchase_date"
                      class="form-control invoice-edit-input"
                      :config="{ dateFormat: 'Y-m-d'}"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Supplier -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="supplier"
                rules="required"
              >
                <b-form-group
                  :label="$t('Supplier')"
                  label-for="supplier"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="purchaseData.supplier_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="supplierOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="supplier"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  :label="$t('Status')"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="purchaseData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                    class="plain"
                    disabled
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Total Amount -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="total-amount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Total Amount')"
                  label-for="total-amount"
                >
                  <b-form-input
                    id="total-amount"
                    v-model="purchaseData.total_amount"
                    class="plain border-light"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    readonly
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Discount -->
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="discount"
                rules="required"
              >
                <b-form-group
                  :label="$t('Discount')"
                  label-for="discount"
                >
                  <b-form-input
                    id="discount"
                    v-model="purchaseData.discount"
                    :state="getValidationState(validationContext)"
                    type="number"
                    trim
                    placeholder=""
                    @focus="$event.target.select()"
                    @input="calTotalAmount()"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Net Amount -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('Net')"
                label-for="net-amount"
                label-class="font-weight-bold"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="net-amount"
                    v-model="purchaseData.net_amount"
                    trim
                    placeholder=""
                    class="plain font-weight-bold border-light"
                    readonly
                  />
                  <b-input-group-append>
                    <b-button
                      ref="paybutton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :disabled="isDisabled"
                      variant="outline-primary"
                      @click="payNow()"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                      />
                      &nbsp;Pay
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>
            </b-col>

            <!-- Field: Payment -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                :label="$t('Cash Payment')"
                label-for="total-payment"
                label-class="font-weight-bold"
              >
                <b-form-input
                  id="total-payment"
                  v-model="purchaseData.total_payment"
                  trim
                  placeholder=""
                  class="plain font-weight-bold border-light"
                  readonly
                />

              </b-form-group>
            </b-col>

          </b-row>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-dark ml-0"
          >
            <b-tab
              active
              @click="initTrHeight"
            >
              <template #title>
                <span>{{ $t('Item') }}</span>
              </template>

              <section
                class="invoice-preview-wrapper"
              >
                <b-row class="mb-1">
                  <!-- Field: Item -->
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('Select Item')"
                      label-for="item"
                    >
                      <v-select
                        v-model="itemId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="itemOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="item"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Quantity -->
                  <b-col
                    md="4"
                  >
                    <b-form-group
                      :label="$t('Quantity')"
                      label-for="quantity"
                    >
                      <b-input-group>
                        <b-form-input
                          id="quantity"
                          v-model="itemQuantity"
                          type="number"
                          trim
                          placeholder=""
                          @keyup.enter="quantityEnter"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="outline-primary"
                            @click="addNewItem"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              class="mr-50"
                            />{{ $t('Add') }}
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>

                    </b-form-group>

                  </b-col>
                </b-row>

                <div
                  ref="formitem"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in purchaseData.purchase_items"
                    :key="index"
                    ref="rowitem"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">

                        <b-row class="flex-grow-1 p-1">

                          <!-- Item Name -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline">{{ $t('Item') }}</label>
                            <p class="mb-1">
                              {{ item.item_id }} - {{ item.item_name }}
                            </p>
                          </b-col>

                          <!-- Price -->
                          <b-col
                            cols="6"
                            lg="3"
                            sm="4"
                          >
                            <label class="d-inline">{{ $t('Price') }}</label>
                            <b-form-input
                              v-model="item.price"
                              type="number"
                              class="mb-1"
                              @input="calItemAmount(item.item_id, item.price, item.quantity)"
                            />
                          </b-col>

                          <!-- Quantity -->
                          <b-col
                            cols="6"
                            lg="2"
                            sm="4"
                          >
                            <label class="d-inline">{{ $t('Quantity') }}</label>
                            <b-form-input
                              v-model="item.quantity"
                              type="number"
                              class="mb-1"
                              @input="calItemAmount(item.item_id, item.price, item.quantity)"
                            />
                          </b-col>

                          <!-- Item Amount -->
                          <b-col
                            cols="12"
                            lg="2"
                            sm="4"
                          >
                            <label class="d-inline">{{ $t('Amount') }}</label>
                            <b-form-input
                              v-model="item.amount"
                              type="text"
                              class="mb-1 plain border-light"
                              readonly
                            />
                          </b-col>
                        </b-row>

                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />

                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

              </section>

            </b-tab>

            <b-tab @click="initTrHeightPayment">
              <template #title>
                <span>{{ $t('Cash Payment') }}</span>
              </template>
              <section class="invoice-preview-wrapper">

                <section
                  class="cash-payment-wrapper"
                >
                  <b-row class="mb-1">
                    <!-- Field: Date -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('Date')"
                        label-for="payment-date"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="CalendarIcon" />
                          </b-input-group-prepend>
                          <flat-pickr
                            id="payment-date"
                            v-model="paymentDate"
                            class="form-control invoice-edit-input"
                            :config="{ dateFormat: 'Y-m-d'}"
                            trim
                            placeholder=""
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <!-- Field: Reference -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('Reference')"
                        label-for="payment-reference"
                      >
                        <b-form-input
                          id="payment-reference"
                          v-model="paymentReference"
                          trim
                          placeholder=""
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Field: Amount -->
                    <b-col
                      md="4"
                    >
                      <b-form-group
                        :label="$t('Amount')"
                        label-for="payment-amount"
                      >
                        <b-input-group>
                          <b-form-input
                            id="payment-amount"
                            v-model="paymentAmount"
                            type="number"
                            trim
                            placeholder=""
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-primary"
                              @click="addNewPayment"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                              />{{ $t('Add') }}
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>

                      </b-form-group>

                    </b-col>
                  </b-row>

                  <div
                    ref="formpayment"
                    class="repeater-form"
                    :style="{height: trHeight}"
                  >
                    <b-row
                      v-for="(payment, index) in purchaseData.purchase_payments"
                      :key="index"
                      ref="rowpayment"
                      class="pb-2"
                    >

                      <!-- Payment Form -->
                      <!-- ? This will be in loop => So consider below markup for single payment -->
                      <b-col cols="12">

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">

                          <b-row class="flex-grow-1 p-1">

                            <!-- Date -->
                            <b-col
                              cols="6"
                              lg="3"
                              sm="4"
                            >
                              <label class="d-inline">{{ $t('Date') }}</label>
                              <b-form-input
                                v-model="payment.payment_date"
                                type="text"
                                class="mb-1 plain"
                                readonly
                              />
                            </b-col>

                            <!-- Reference -->
                            <b-col
                              cols="6"
                              lg="4"
                              sm="4"
                            >
                              <label class="d-inline">{{ $t('Reference') }}</label>
                              <b-form-input
                                v-model="payment.reference"
                                type="text"
                                class="mb-1"
                              />
                            </b-col>

                            <!-- Amount -->
                            <b-col
                              cols="12"
                              lg="4"
                              sm="4"
                            >
                              <label class="d-inline">{{ $t('Amount') }}</label>
                              <b-form-input
                                v-model="payment.amount"
                                type="number"
                                class="mb-1"
                                @input="calTotalPayment()"
                              />
                            </b-col>
                          </b-row>

                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removePayment(index)"
                            />

                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>

                </section>

              </section>

            </b-tab>

          </b-tabs>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-row>

            <!-- Field: Reference -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('Reference')"
                label-for="reference"
              >
                <b-form-textarea
                  id="reference"
                  v-model="purchaseData.reference"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <!-- Field: Remark -->
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('Remark')"
                label-for="remark"
              >
                <b-form-textarea
                  id="remark"
                  v-model="purchaseData.remark"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="onSubmit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}

            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
  BTabs, BTab, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import flatPickr from 'vue-flatpickr-component'
import OfflineDB from '@/libs/offline-db'
import store from '@/store'
import router from '@/router/index'
import storeModule from '../../../common/storeModule'
import purchaseStoreModule from '../purchaseStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTabs,
    BTab,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      itemOptions: [],
      supplierOptions: [],
      itemId: null,
      itemQuantity: null,
      paymentDate: this.today,
      paymentReference: null,
      paymentAmount: null,
      offlineDB: new OfflineDB(),
      isDisabled: true,
      statusOptions: [
      // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
        { label: this.$t('Unpaid'), value: '1' },
        { label: this.$t('Partially Paid'), value: '2' },
        { label: this.$t('Paid'), value: '3' },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'purchaseData.total_amount': function () {
      this.payButtonControl()
    },
    // eslint-disable-next-line func-names
    'purchaseData.purchase_payments': function () {
      this.payButtonControl()
    },
  },
  mounted() {
    this.initTrHeight()
    this.initTrHeightPayment()
    this.loadSuppliers()
    this.loadItems()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    window.addEventListener('resize', this.initTrHeightPayment)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
    window.removeEventListener('resize', this.initTrHeightPayment)
  },
  methods: {
    quantityEnter() {
      this.addNewItem()
      document.getElementById('item').focus()
      // this.$refs.item.$el.focus()
    },
    loadSuppliers() {
      store
        .dispatch('common/fetchSuppliers', { id_name: 1 })
        .then(response => {
          const { suppliers } = response.data.data
          for (let i = 0; i < suppliers.length; i += 1) {
            this.supplierOptions.push({ label: suppliers[i].name, value: suppliers[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadItems() {
      store
        .dispatch('common/fetchItems', { id_name: 1, purchase: 1 })
        .then(response => {
          const { items } = response.data.data
          this.offlineDB.items.clear()
          this.offlineDB.items.bulkAdd(items)
          // const someFriends = await this.offlineDB.items.where({ id: 2 }).first()
          // console.log(someFriends)
          for (let i = 0; i < items.length; i += 1) {
            this.itemOptions.push({ label: items[i].name, value: items[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    cancel() {
      this.$router.push({ name: 'purchase-list' })
    },
    async addNewItem() {
      if (this.itemId !== null && this.itemQuantity !== null) {
        this.$refs.formitem.style.overflow = 'hidden'
        const selectedItem = await this.offlineDB.items.where({ id: this.itemId }).first()

        if (this.purchaseData.purchase_items.some(obj => obj.item_id === this.itemId)) {
          this.$swal('Warning', 'Item already exists', 'warning')
          return
        }

        this.itemFormBlankItem.item_name = selectedItem.name
        this.itemFormBlankItem.item_id = selectedItem.id
        this.itemFormBlankItem.price = Number(selectedItem.purchase_price)
        this.itemFormBlankItem.quantity = Number(this.itemQuantity)
        this.itemFormBlankItem.amount = Number(selectedItem.purchase_price) * Number(this.itemQuantity)

        this.purchaseData.purchase_items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.itemId = null
        this.itemQuantity = null

        this.calTotalAmount()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rowitem[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formitem.style.overflow = null
          }, 350)
        })
      }
    },
    addNewPayment() {
      if (this.paymentDate !== null && this.paymentAmount !== null) {
        this.$refs.formpayment.style.overflow = 'hidden'

        if (this.purchaseData.purchase_payments.some(obj => obj.payment_date === this.paymentDate)) {
          this.$swal('Warning', 'Payment already exists on that date', 'warning')
          return
        }

        this.paymentFromBlankPayment.payment_date = this.paymentDate
        this.paymentFromBlankPayment.reference = this.paymentReference
        this.paymentFromBlankPayment.amount = this.paymentAmount

        this.purchaseData.purchase_payments.push(JSON.parse(JSON.stringify(this.paymentFromBlankPayment)))

        this.paymentDate = this.today
        this.paymentReference = null
        this.paymentAmount = null

        this.calTotalPayment()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rowpayment[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formpayment.style.overflow = null
          }, 350)
        })
      }
    },
    removeItem(index) {
      this.purchaseData.purchase_items.splice(index, 1)
      this.calTotalAmount()
      this.trTrimHeight(this.$refs.rowitem[0].offsetHeight)
    },
    removePayment(index) {
      this.purchaseData.purchase_payments.splice(index, 1)
      this.calTotalPayment()
      this.trTrimHeight(this.$refs.rowpayment[0].offsetHeight)
    },
    calItemAmount(itemId, price, quantity) {
      const index = this.purchaseData.purchase_items.findIndex((obj => obj.item_id === itemId))
      const amount = Number(price) * Number(quantity)
      this.purchaseData.purchase_items[index].amount = amount
      this.calTotalAmount()
    },
    calTotalAmount() {
      this.purchaseData.total_amount = this.purchaseData.purchase_items.reduce((a, b) => +a + +b.amount, 0)
      this.purchaseData.net_amount = this.purchaseData.total_amount - this.purchaseData.discount
      this.setStatus()
    },
    calTotalPayment() {
      this.purchaseData.total_payment = this.purchaseData.purchase_payments.reduce((a, b) => +a + +b.amount, 0)
      this.setStatus()
    },
    setStatus() {
      // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
      if (this.purchaseData.total_payment > 0) {
        if (this.purchaseData.total_payment === this.purchaseData.net_amount) {
          this.purchaseData.status = '3'
        }
        if (this.purchaseData.total_payment < this.purchaseData.net_amount) {
          this.purchaseData.status = '2'
        }
      } else if (this.purchaseData.total_payment === 0) {
        this.purchaseData.status = '1'
      }
    },
    payNow() {
      this.paymentFromBlankPayment.payment_date = this.today
      this.paymentFromBlankPayment.reference = 'Cash Pay'
      this.paymentFromBlankPayment.amount = this.purchaseData.net_amount
      this.purchaseData.purchase_payments = []
      this.purchaseData.purchase_payments.push(JSON.parse(JSON.stringify(this.paymentFromBlankPayment)))

      this.paymentDate = this.today
      this.paymentReference = null
      this.paymentAmount = null

      this.calTotalPayment()

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowpayment[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formpayment.style.overflow = null
        }, 350)
      })
    },
    payButtonControl() {
      if (this.purchaseData.net_amount !== null) {
        if (this.purchaseData.net_amount > 0) {
          if (this.purchaseData.total_payment === this.purchaseData.net_amount) {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        } else {
          this.isDisabled = true
        }
      } else {
        this.isDisabled = true
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formitem.scrollHeight)
      })
    },
    initTrHeightPayment() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formpayment.scrollHeight)
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'purchase'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, purchaseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    /** request data format
    {
      "purchase_date" : "2021-03-21",
      "supplier_id" : 3,
      "total_amount" : 1000,
      "total_payment" : 1000,
      "discount" : 0,
      "reference" : "457",
      "remark" : "none",
      "sale_region_id" : 1,
      "status" : 3,
      "purchase_items" : [
          {
            "item_id" : 2,
            "quantity" : 1,
            "price" : 500,
            "amount" : 500
          },
      ],
      "purchase_payments" : [
          {
              "payment_date" : "2021-03-21",
              "amount" : 1000,
              "reference" : "cash"
          }
      ]
    }
    */

    const itemFormBlankItem = {
      item_name: '',
      item_id: null,
      price: 0,
      quantity: 0,
      amount: 0,
    }

    const current = new Date()
    const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
    const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
    const today = `${current.getFullYear()}-${month}-${date}`

    const purchaseData = ref({
      purchase_date: today,
      supplier_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_payment: 0,
      reference: '',
      remark: '',
      branch_name: '',
      status: null,
      purchase_items: [],
      purchase_payments: [],
    })

    const paymentFromBlankPayment = {
      payment_date: today,
      reference: '',
      amount: 0,
    }

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(purchaseData.value))
      store.dispatch('purchase/add', data)
        .then(response => {
          if (response.status === 201) {
            router.push({ name: 'purchase-view', params: { id: response.data.data.id } })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      purchaseData,
      itemFormBlankItem,
      paymentFromBlankPayment,
      today,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, [dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, [dir] .vs--disabled .vs__open-indicator {
  background-color: #ffffff;
}

</style>
